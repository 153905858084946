import type { ResourceObject } from 'ts-json-api';

import type { AccountApiSchema } from '~/data/.types/api/generated/Account';
import { ResourceTypes } from '~/data/.types/apiTypes';
import type { PlanResource } from '~/data/plan/.types/planTypes';

export function getPlanFromAccount(account: AccountApiSchema | undefined, included: ResourceObject[] | undefined) {
  if (!account && !included) {
    return null;
  }

  const planId = account?.relationships?.plan?.data?.id;

  return included?.filter((item) => item.type === ResourceTypes.Plan && planId === item.id)[0] as PlanResource;
}
